import { GatewayImportExportAssujetti, GatewayMe } from "@conformite/gateway";
import {
  displayErrorToast,
  displaySucessToast,
  displayWarningToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { GatewayImportExportAssujettiAPI } from "@src/api/importExportAssujetti.api";
import { RowError } from "@src/helper/excel/RowError";
import {
  ExtractDataExcelSuccessResponse,
  extractDataExcel,
} from "@src/helper/excel/excel.import.helper";
import { useAssujetti } from "@src/store/store.assujetti";
import { chunk } from "lodash";
import { ChangeEvent, useState } from "react";
import { assujettiExcelTemplate } from "../AssujettiExcelTemplate";
import { transformRowsForToAPIRows } from "./ImportAssujettiExcelMapping";

type ImportAssujettisParams = {
  me: GatewayMe.GetMeResponse;
  successCallback?: (
    success: GatewayImportExportAssujetti.ImportAssujetiResponseType["success"]
  ) => void;
  filterBeforeImport?: (
    extractedData: ExtractDataExcelSuccessResponse<GatewayImportExportAssujetti.AssujettiRowImport>
  ) => boolean;
  clean?: boolean;
};
const importBatch = async (
  linesWithoutErrorsToImport: ExtractDataExcelSuccessResponse<GatewayImportExportAssujetti.AssujettiRowImport>[],
  clean: boolean
) => {
  const chunkImport = chunk(
    linesWithoutErrorsToImport,
    parseInt(import.meta.env.VITE_IMPORT_CHUNK_SIZE, 10)
  );
  const firstChunk = chunkImport.shift();
  if (!firstChunk) return { failures: [], success: [] };
  const responses = [
    await GatewayImportExportAssujettiAPI.importAssujetti(firstChunk, clean),
  ];
  const chunkParallelCall = chunk(
    chunkImport,
    parseInt(import.meta.env.VITE_IMPORT_PARALLEL_CALL, 10)
  );
  // eslint-disable-next-line no-restricted-syntax
  for (const chunkCall of chunkParallelCall) {
    const promises = chunkCall.map((importAssujettis) =>
      GatewayImportExportAssujettiAPI.importAssujetti(importAssujettis)
    );
    // eslint-disable-next-line no-await-in-loop
    responses.push(...(await Promise.all(promises)));
  }
  return responses.reduce(
    (acc, response) => ({
      failures: acc.failures.concat(response.failures),
      success: acc.success.concat(response.success),
    }),
    { failures: [], success: [] }
  );
};

export const useImportAssujetti = () => {
  const { setEntries } = useAssujetti();
  const [isImporting, setIsImporting] = useState(false);
  const importAssujettis =
    ({
      me,
      successCallback: callback,
      filterBeforeImport,
      clean = false,
    }: ImportAssujettisParams) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsImporting(true);
      const file = e.target.files?.item(0);
      if (!file || !me) return;

      extractDataExcel(
        file,
        assujettiExcelTemplate,
        transformRowsForToAPIRows(me.entreprisePrincipale.millesimeActuel),
        (data) => {
          if (data instanceof RowError) return true;
          const valuesLevel1 = Object.values(data);
          return valuesLevel1.some((v) =>
            Object.values(v).some((val) =>
              Array.isArray(val) ? val.length > 0 : val
            )
          );
        }
      )
        .then(async ([linesWithFrontErrors, linesWithoutErrors]) => {
          const linesWithoutErrorsToImport = filterBeforeImport
            ? linesWithoutErrors.filter(filterBeforeImport)
            : linesWithoutErrors;

          if (linesWithFrontErrors.length > 0)
            console.warn("Erreur Import Front: ", linesWithFrontErrors);
          const { failures, success } = await importBatch(
            linesWithoutErrorsToImport,
            clean
          );
          if (failures.length > 0)
            console.warn("Erreur Import Back: ", failures);
          const failedRowIndexes = linesWithFrontErrors
            .map((lineError) => lineError.row)
            .concat(failures.map((failure) => failure.rowIndex))
            .map((line) => line + assujettiExcelTemplate.firstRowIndex + 1);

          if (failedRowIndexes.length > 0) {
            displayWarningToast(
              `Attention, ${
                failedRowIndexes.length
              } erreurs ont été détectées lors de l'import. Les erreurs ont été détectées aux lignes ${failedRowIndexes
                .sort()
                .join(", ")}.`,
              { hideAfter: 5000 }
            );
          }
          setEntries(success);
          displaySucessToast(`${success.length} lignes ont été importées.`, {
            hideAfter: 5000,
          });
          callback?.(success);
        })
        .catch((err) => {
          console.error(err);
          displayErrorToast(
            "une erreur inconnue est survenue lors du traitement du fichier excel"
          );
        })
        .finally(() => setIsImporting(false));
    };

  return { importAssujettis, isImporting };
};
