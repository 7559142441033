import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { GatewaySearchExistingEntreprisesAPI } from "@src/api/search-existing-entreprise.api";
import { TEMPLATE_SOCIETES_FIRST_ROW } from "@src/helper/excel/excel.export.helper";
import { invertMap, recordKeysToUppercase } from "@src/helper/object.helper";
import {
  formeJuridiqueByExcelLabel,
  organisationProfessionnelleByExcelLabel,
} from "@src/societes/export/EntrepriseExport.definition";
import {
  paysRecord,
  typeVoieRecord,
} from "@src/societes/form/EntrepriseAdresseForm";
import { Cell, CellRichTextValue, Row, Workbook, Worksheet } from "exceljs";
import { chain, has } from "lodash";
import { ChangeEvent, useState } from "react";

const columnKeyMapping = {
  SIREN: "SIREN",
  NUMERO_ORIAS: "NUMERO ORIAS",
  FORME_JURIDIQUE: "FORME JURIDIQUE",
  RAISON_SOCIALE: "RAISON SOCIALE",
  TYPE_VOIE: "TYPE VOIE",
  NUMERO_VOIE: "NUMERO VOIE",
  LIBELLE_VOIE: "ADRESSE",
  COMPLEMENT_ADRESSE: "COMPLEMENT ADRESSE",
  CODE_POSTAL: "CODE POSTAL",
  VILLE: "VILLE",
  PAYS: "PAYS",
  TRANCHE_EFFECTIF: "TRANCHE D'EFFECTIF",
  CHIFFRE_AFFAIRE: "CHIFFRE D'AFFAIRE",
  CATEGORIE_ORIAS_COA: "CATEGORIE ORIAS COA",
  COA_ACCESSOIRE: "COA ACCESSOIRE",
  CATEGORIE_ORIAS_MIA: "CATEGORIE ORIAS MIA",
  MIA_ACCESSOIRE: "MIA ACCESSOIRE",
  CATEGORIE_ORIAS_COBSP: "CATEGORIE ORIAS COBSP",
  COBSP_ACCESSOIRE: "COBSP ACCESSOIRE",
  CATEGORIE_ORIAS_MIOBSP: "CATEGORIE ORIAS MIOBSP",
  MIOBSP_ACCESSOIRE: "MIOBSP ACCESSOIRE",
  SOUS_CATEGORIE_COBSP_1: "SOUS-CATEGORIE COBSP 1",
  SOUS_CATEGORIE_COBSP_2: "SOUS-CATEGORIE COBSP 2",
  SOUS_CATEGORIE_COBSP_3: "SOUS-CATEGORIE COBSP 3",
  SOUS_CATEGORIE_COBSP_4: "SOUS-CATEGORIE COBSP 4",
  SOUS_CATEGORIE_COBSP_5: "SOUS-CATEGORIE COBSP 5",
  SOUS_CATEGORIE_COBSP_6: "SOUS-CATEGORIE COBSP 6",
  SOUS_CATEGORIE_MIOBSP_1: "SOUS-CATEGORIE MIOBSP 1",
  SOUS_CATEGORIE_MIOBSP_2: "SOUS-CATEGORIE MIOBSP 2",
  SOUS_CATEGORIE_MIOBSP_3: "SOUS-CATEGORIE MIOBSP 3",
  SOUS_CATEGORIE_MIOBSP_4: "SOUS-CATEGORIE MIOBSP 4",
  SOUS_CATEGORIE_MIOBSP_5: "SOUS-CATEGORIE MIOBSP 5",
  SOUS_CATEGORIE_MIOBSP_6: "SOUS-CATEGORIE MIOBSP 6",
  ORGANISATION_PRO_1: "ORGANISATION PRO 1",
  ORGANISATION_PRO_2: "ORGANISATION PRO 2",
  ORGANISATION_PRO_3: "ORGANISATION PRO 3",
  CATEGORIE_REPRESENTATION: "CATEGORIE DE REPRESENTATION",
} as const;
type ColumnKey = keyof typeof columnKeyMapping;
const columnsToKeys: Record<string, ColumnKey> = Object.entries(
  columnKeyMapping
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {}) as Record<
  string,
  ColumnKey
>;
const isRichText = (cell: Cell["value"]): cell is CellRichTextValue =>
  has(cell, "richText");
const getCellValueFromRow = (row: Row, key: ColumnKey): string | undefined => {
  let cell: Cell | undefined;
  row.eachCell((cellRow) => {
    if (row.worksheet.getColumn(cellRow.col).key === key) {
      cell = cellRow;
    }
  });
  const value = cell?.value;
  if (isRichText(value)) {
    return value.richText
      .map(({ text }) => text)
      .join("")
      .trim();
  }
  return value?.toString().trim();
};

const addKeyToColumns = (worksheet: Worksheet) =>
  worksheet.columns.forEach((column, columnNumber) => {
    const headerValue = worksheet
      .getRow(2)
      .getCell(columnNumber + 1)
      .value?.toString()
      .trim();
    if (!headerValue) return;
    const key = columnsToKeys[headerValue];
    column.key = key;
  });

const labelActiviteBancaire: Record<
  string,
  GatewayInfoSocieteOrias.ActiviteBancaire
> = {
  "Services de paiement":
    GatewayInfoSocieteOrias.ActiviteBancaire.SERVICE_PAIEMENT,
  "Regroupement de crédits":
    GatewayInfoSocieteOrias.ActiviteBancaire.REGROUPEMENT_CREDITS,
  "Crédits à la consommation":
    GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_CONSOMMATION,
  "Prêts viagers hypothécaires":
    GatewayInfoSocieteOrias.ActiviteBancaire.PRET_VIAGER_HYPOTECAIRE,
  "Crédits immobiliers":
    GatewayInfoSocieteOrias.ActiviteBancaire.CREDIT_IMMOBILIER,
  "Autres activités": GatewayInfoSocieteOrias.ActiviteBancaire.AUTRES_ACTIVITES,
};
const labelActiviteBancaireUppercase = recordKeysToUppercase(
  labelActiviteBancaire
);

const labelTrancheEffectif: Record<string, GatewayEntreprise.TrancheEffectif> =
  {
    "Pas de salarié": GatewayEntreprise.TrancheEffectif.AUCUN,
    "1 ou 2 salariés": GatewayEntreprise.TrancheEffectif.DE_1_A_2,
    "3 à 5 salariés": GatewayEntreprise.TrancheEffectif.DE_3_A_5,
    "6 à 9 salariés": GatewayEntreprise.TrancheEffectif.DE_6_A_9,
    "10 à 19 salariés": GatewayEntreprise.TrancheEffectif.DE_10_A_19,
    "20 à 49 salariés": GatewayEntreprise.TrancheEffectif.DE_20_A_49,
    "50 à 99 salariés": GatewayEntreprise.TrancheEffectif.DE_50_A_99,
    "100 à 199 salariés": GatewayEntreprise.TrancheEffectif.DE_100_A_199,
    "200 à 249 salariés": GatewayEntreprise.TrancheEffectif.DE_200_A_249,
    "250 à 499 salariés": GatewayEntreprise.TrancheEffectif.DE_250_A_499,
    "500 à 999 salariés": GatewayEntreprise.TrancheEffectif.DE_500_A_999,
    "1000 à 1999 salariés": GatewayEntreprise.TrancheEffectif.DE_1000_A_1999,
    "2000 à 4999 salariés": GatewayEntreprise.TrancheEffectif.DE_2000_A_4999,
    "5000 à 9999 salariés": GatewayEntreprise.TrancheEffectif.DE_5000_A_9999,
    "10000 salariés et plus": GatewayEntreprise.TrancheEffectif.PLUS_DE_10000,
  };
const labelTrancheEffectifUppercase =
  recordKeysToUppercase(labelTrancheEffectif);

const labelCategorieRepresentation: Record<
  string,
  GatewayInfoSocieteOrias.CategorieRepresentation
> = {
  "Courtier de moins de 20 salariés":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_MOINS_20_SALARIES,
  "Courtier de plus de 20 salariés":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIERS_PLUS_20_SALARIES,
  "Courtier grossiste":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_GROSSISTE,
  "Courtier affinitaire":
    GatewayInfoSocieteOrias.CategorieRepresentation.COURTIER_AFFINITAIRE,
  Comparateur: GatewayInfoSocieteOrias.CategorieRepresentation.COMPARATEUR,
  "Mandataire d'intermédiaire en assurance":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .MANDATAIRE_INTERMEDIAIRE_ASSURANCE,
  "Courtier en opérations de banque et en services de paiement":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .COURTIER_OPERATION_DE_BANQUE_ET_SERVICES_DE_PAIEMENT,
  "Mandataire d'intermédiaire en opérations de banque et en services de paiement":
    GatewayInfoSocieteOrias.CategorieRepresentation
      .MANDATAIRE_INTERMEDIAIRE_OPERATION_DE_BANQUE_ET_SERVICE_DE_PAIEMENT,
};
const labelCategorieRepresentationUppercase = recordKeysToUppercase(
  labelCategorieRepresentation
);

const labelTypeVoie = invertMap(typeVoieRecord);
const labelTypeVoieUppercase = recordKeysToUppercase(labelTypeVoie);
const labelFormeJuridiqueUppercase = recordKeysToUppercase(
  formeJuridiqueByExcelLabel
);
const labelPays = invertMap(paysRecord);
const labelPaysUppercase = recordKeysToUppercase(labelPays);
const labelOrganisationUppercase = recordKeysToUppercase(
  organisationProfessionnelleByExcelLabel
);

const isOui = (value: string | undefined): boolean =>
  value?.toUpperCase() === "OUI";

function getCellValueEnumFromRow<T>(
  row: Row,
  key: ColumnKey,
  uppercaseLabelToEnum: Record<string, T>
): T | undefined {
  const value = getCellValueFromRow(row, key);
  if (value === undefined) return undefined;
  return uppercaseLabelToEnum[value.toUpperCase().trim()];
}

const rowToEntreprise = (
  row: Row
): Omit<AdhesionDataEntreprisesItem, "index"> => {
  return {
    siren: getCellValueFromRow(row, "SIREN")
      ?.replaceAll(" ", "")
      .padStart(9, "0"),
    numeroOrias: getCellValueFromRow(row, "NUMERO_ORIAS")?.padStart(8, "0"),
    formeJuridique: getCellValueEnumFromRow(
      row,
      "FORME_JURIDIQUE",
      labelFormeJuridiqueUppercase
    ),
    raisonSociale: getCellValueFromRow(row, "RAISON_SOCIALE"),
    typeVoie: getCellValueEnumFromRow(row, "TYPE_VOIE", labelTypeVoieUppercase),
    numeroVoie: getCellValueFromRow(row, "NUMERO_VOIE"),
    libelleVoie: getCellValueFromRow(row, "LIBELLE_VOIE"),
    complementAdresse: getCellValueFromRow(row, "COMPLEMENT_ADRESSE"),
    codePostal: getCellValueFromRow(row, "CODE_POSTAL")?.padStart(5, "0"),
    ville: getCellValueFromRow(row, "VILLE"),
    pays: getCellValueEnumFromRow(row, "PAYS", labelPaysUppercase),
    trancheEffectif: getCellValueEnumFromRow(
      row,
      "TRANCHE_EFFECTIF",
      labelTrancheEffectifUppercase
    ),
    chiffreAffaire: !Number.isNaN(
      Number(getCellValueFromRow(row, "CHIFFRE_AFFAIRE"))
    )
      ? Number(getCellValueFromRow(row, "CHIFFRE_AFFAIRE"))
      : undefined,
    categoriesOrias: [
      isOui(getCellValueFromRow(row, "CATEGORIE_ORIAS_COA"))
        ? GatewayInfoSocieteOrias.Categorie.COA
        : undefined,
      isOui(getCellValueFromRow(row, "CATEGORIE_ORIAS_MIA"))
        ? GatewayInfoSocieteOrias.Categorie.MIA
        : undefined,
      isOui(getCellValueFromRow(row, "CATEGORIE_ORIAS_COBSP"))
        ? GatewayInfoSocieteOrias.Categorie.COBSP
        : undefined,
      isOui(getCellValueFromRow(row, "CATEGORIE_ORIAS_MIOBSP"))
        ? GatewayInfoSocieteOrias.Categorie.MIOBSP
        : undefined,
    ].filter((el) => el !== undefined) as GatewayInfoSocieteOrias.Categorie[],
    coaAccessoire: isOui(getCellValueFromRow(row, "COA_ACCESSOIRE")),
    miaAccessoire: isOui(getCellValueFromRow(row, "MIA_ACCESSOIRE")),
    cobspAccessoire: isOui(getCellValueFromRow(row, "COBSP_ACCESSOIRE")),
    miobspAccessoire: isOui(getCellValueFromRow(row, "MIOBSP_ACCESSOIRE")),
    sousCategorieCobsp: [
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_1",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_2",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_3",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_4",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_5",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_COBSP_6",
        labelActiviteBancaireUppercase
      ),
    ].filter((value) => value) as GatewayInfoSocieteOrias.ActiviteBancaire[],
    sousCategorieMiobsp: [
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_1",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_2",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_3",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_4",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_5",
        labelActiviteBancaireUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "SOUS_CATEGORIE_MIOBSP_6",
        labelActiviteBancaireUppercase
      ),
    ].filter((value) => value) as GatewayInfoSocieteOrias.ActiviteBancaire[],
    organisationPro: [
      getCellValueEnumFromRow(
        row,
        "ORGANISATION_PRO_1",
        labelOrganisationUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "ORGANISATION_PRO_2",
        labelOrganisationUppercase
      ),
      getCellValueEnumFromRow(
        row,
        "ORGANISATION_PRO_3",
        labelOrganisationUppercase
      ),
    ].filter(
      (value) => value
    ) as GatewayInfoSocieteOrias.OrganisationProfessionnelle[],
    categorieRepresentation: getCellValueEnumFromRow(
      row,
      "CATEGORIE_REPRESENTATION",
      labelCategorieRepresentationUppercase
    ),
  };
};

const getStringParse = (fieldLabel: string) =>
  zod.string({ required_error: `${fieldLabel} non renseigné` });

const getAdhesionDataEntrepriseSchema = (
  payload: AdhesionDataEntreprisesItem
) =>
  zod.object({
    siren: zod
      .string({ required_error: `SIREN non renseigné` })
      .refine((v) => /^\d{9}$/.test(v), "SIREN invalide"),
    raisonSociale: getStringParse("Raison sociale"),
    trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif, {
      required_error: "Tranche d'effectif invalide",
    }),
    typeVoie: zod.nativeEnum(GatewayAdresse.TypeVoie, {
      required_error: "Type de voie invalide",
    }),
    libelleVoie: getStringParse("Libellé de voie"),
    codePostal: zod
      .string({ required_error: "Code postal non renseigné" })
      .refine((v) => /^\d{5}$/.test(v), "Code postal invalide"),
    ville: getStringParse("Ville"),
    categoriesOrias: zod
      .array(
        zod.nativeEnum(GatewayInfoSocieteOrias.Categorie, {
          required_error: "Catégorie ORIAS invalide",
        })
      )
      .min(1, "1 Catégorie ORIAS au minimum"),
    sousCategorieCobsp: zod
      .array(
        zod
          .nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire, {
            required_error: "1 sous catégorie cobsp au minimum",
          })
          .optional()
      )
      .superRefine((data, ctx) => {
        const isCobsp = payload.categoriesOrias?.includes(
          GatewayInfoSocieteOrias.Categorie.COBSP
        );
        if (isCobsp && data.length === 0) {
          ctx.addIssue({
            path: ["sousCategorieCobsp"],
            message: "1 sous catégorie COBSP minimum",
            code: "custom",
          });
        }

        return true;
      }),
    sousCategorieMiobsp: zod
      .array(
        zod
          .nativeEnum(GatewayInfoSocieteOrias.ActiviteBancaire, {
            required_error: "1 sous catégorie miobsp au minimum",
          })
          .optional()
      )
      .superRefine((data, ctx) => {
        const isMiobsp = payload.categoriesOrias?.includes(
          GatewayInfoSocieteOrias.Categorie.MIOBSP
        );
        if (isMiobsp && data.length === 0) {
          ctx.addIssue({
            path: ["sousCategorieMiobsp"],
            message: "1 sous catégorie MIOBSP minimum",
            code: "custom",
          });
        }

        return true;
      }),
    organisationPro: zod
      .array(
        zod.nativeEnum(GatewayInfoSocieteOrias.OrganisationProfessionnelle, {
          required_error: "Organisation professionnelle invalide",
        })
      )
      .min(1, "1 organisation professionnelle minimum"),
    chiffreAffaire: zod.number({
      required_error: "Chiffre d'affaire invalide",
    }),
    categorieRepresentation: zod.nativeEnum(
      GatewayInfoSocieteOrias.CategorieRepresentation,
      { required_error: "Catégorie de représentation invalide" }
    ),
    pays: zod.nativeEnum(GatewayAdresse.Pays, {
      required_error: "Pays invalide",
    }),
    formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique, {
      required_error: "Forme juridique invalide",
    }),
  });

type AdhesionDateEntrepriseSchema = zod.infer<
  ReturnType<typeof getAdhesionDataEntrepriseSchema>
>;

export const isValidSociete = (
  societe: AdhesionDataEntreprisesItem
): zod.SafeParseReturnType<
  AdhesionDateEntrepriseSchema,
  AdhesionDateEntrepriseSchema
> => {
  return getAdhesionDataEntrepriseSchema(societe).safeParse(societe);
};

export type ImportSocietesItem = {
  index: number;
  societe: AdhesionDataEntreprisesItem;
};

export type ImportSocietesError = ImportSocietesItem & {
  errors: {
    description: string;
    key: string;
  }[];
};

export type ImportSocietesDoublon = ImportSocietesItem & {
  description: string;
};

export type ImportSocietesReturn = {
  societesToAdd: ImportSocietesItem[];
  societesInError: ImportSocietesError[];
  societesDoublons: ImportSocietesDoublon[];
};

const validateAndUpdateState = async (
  entreprises: AdhesionDataEntreprisesItem[],
  addData: (data: ImportSocietesReturn) => void
) => {
  const existingSirens =
    await GatewaySearchExistingEntreprisesAPI.bySirensExcludingPrincipale({
      sirens: entreprises
        .map(({ siren }) => siren)
        .filter((v) => !!v) as string[],
    });

  const firstRowBySiren: Record<string, number> = entreprises.reduce<
    Record<string, number>
  >((acc, curr, index) => {
    if (curr.siren && !acc[curr.siren]) {
      return {
        ...acc,
        [curr.siren]: index + TEMPLATE_SOCIETES_FIRST_ROW,
      };
    }
    return acc;
  }, {});

  const { societesToAdd, societesInError, societesDoublons } = chain(
    entreprises
  )
    .filter((societe) =>
      // la ligne contient des données
      Object.values(societe).some((val) =>
        Array.isArray(val) ? val.length > 0 : val
      )
    )
    .map((societe, index) => ({
      societe,
      index: index + TEMPLATE_SOCIETES_FIRST_ROW,
    }))
    .reduce<ImportSocietesReturn>(
      (acc, curr) => {
        if (curr.societe.siren) {
          const entrepriseAlreadyExist = existingSirens.includes(
            curr.societe.siren
          );
          const isValid = isValidSociete(curr.societe);
          if (entrepriseAlreadyExist)
            acc.societesDoublons.push({
              ...curr,
              description: "Existe déjà dans la liste des sociétés",
            });
          else if (firstRowBySiren[curr.societe.siren] !== curr.index) {
            acc.societesDoublons.push({
              ...curr,
              description: `Déjà importé ligne ${
                firstRowBySiren[curr.societe.siren]
              }`,
            });
          } else if (!isValid.success)
            acc.societesInError.push({
              ...curr,
              errors: isValid.error.errors.map((e) => ({
                description: e.message,
                key: e.path[0].toString(),
              })),
            });
          else acc.societesToAdd.push(curr);
        }
        return acc;
      },
      { societesToAdd: [], societesInError: [], societesDoublons: [] }
    )
    .value();

  addData({
    societesToAdd,
    societesInError,
    societesDoublons,
  });
};

export const useImportSocietes = (
  addData: (data: ImportSocietesReturn) => void,
  actionAfterImport?: () => void
) => {
  const [status, setStatus] = useState<"loading" | "loaded" | "init">("init");
  return {
    status,
    validateAndUpdateState,
    handleImportSocietes: (e: ChangeEvent<HTMLInputElement>): void => {
      setStatus("loading");
      const file = e.target.files?.item(0);
      if (!file) return;
      file
        .arrayBuffer()
        .then(async (buffer) => {
          const workbook = new Workbook();
          const workbookBuffer = await workbook.xlsx.load(buffer);
          const worksheet = workbookBuffer.getWorksheet(1);
          if (!worksheet) throw new Error("no worksheet");
          addKeyToColumns(worksheet);
          const societesImported: AdhesionDataEntreprisesItem[] = [];
          const virginSheet = workbookBuffer.getWorksheet(1);
          if (!virginSheet) throw new Error("no worksheet");
          virginSheet.eachRow((row, rowNumber) => {
            if (rowNumber <= 2) return;
            societesImported.push(rowToEntreprise(row));
          });
          await validateAndUpdateState(societesImported, addData);
          actionAfterImport?.();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setStatus("loaded"));
    },
  };
};
