function LinkDocumentationHere() {
  return <a href="https://aide.endya.fr/faq/outils-conformite">ici</a>;
}

export function ControleQuinquennalExplainAxeGarantieFinanciereIobsp() {
  return (
    <div>
      <p>
        Si vous percevez, même à titre occasionnel, des fonds de vos clients
        dans le cadre de l&apos;activité d&apos;IOBSP, les documents sollicités
        ci-dessous permettront de vérifier la cohérence de la garantie
        financière souscrite avec les fonds réellement encaissés de clients.
      </p>
      <br />
      <p>
        Pièces à fournir, que vous perceviez ou non des fonds pour le compte ou
        à destination de tiers :
      </p>
      <ul>
        <li>
          Attestation de votre expert-comptable ou commissaire aux comptes
          précisant le détail des fonds encaissés au sens des articles L.519-4
          du code monétaire et financier et art.2 Arrêté du 26/06/2012 (cf.
          modèle accessible dans la documentation Endya disponible{" "}
          <LinkDocumentationHere />)
        </li>
        <p>
          <b>OU</b>, à défaut, si vous n&apos;avez pas d&apos;expert-comptable
          ou commissaire aux comptes ou si ce dernier ne souhaite/peut pas
          compléter l&apos;attestation
        </p>
        <li>
          Les comptes classes 4 et 5 de votre grand livre et votre bilan N-1.{" "}
        </li>
      </ul>
    </div>
  );
}
