import { GatewayEntreprise } from "@conformite/gateway";
import { userSession } from "@src/session/UserSession";
import { captchaAxiosInstance } from "./utils/captchaAxiosInstance";

export class GatewayEntrepriseApi {
  static async getEntrepriseStatus(
    siren: string
  ): Promise<GatewayEntreprise.EntrepriseStatusResponse> {
    const result =
      await captchaAxiosInstance.get<GatewayEntreprise.EntrepriseStatusResponse>(
        `${GatewayEntreprise.entrepriseControllerUrl}/${siren}/status`
      );
    return result.data;
  }

  static async getEntrepriseAControllerStatus(
    siren: string
  ): Promise<GatewayEntreprise.EntrepriseStatusResponse> {
    const result =
      await userSession.axiosInstance.get<GatewayEntreprise.EntrepriseStatusResponse>(
        `${GatewayEntreprise.entrepriseControllerUrl}/a-controller/${siren}/status`
      );
    return result.data;
  }

  static async listMyEntreprises(millesime: number) {
    const result = await userSession.axiosInstance.get<
      GatewayEntreprise.EntrepriseAControllerResponseType[]
    >(`${GatewayEntreprise.entrepriseControllerUrl}/list/${millesime}`);
    return result.data;
  }

  static async getBySiren(
    siren: string,
    millesime: number
  ): Promise<GatewayEntreprise.EntrepriseAControllerResponseType> {
    const res =
      await userSession.axiosInstance.get<GatewayEntreprise.EntrepriseAControllerResponseType>(
        `${GatewayEntreprise.entrepriseControllerUrl}/${millesime}/${siren}`
      );

    return res.data;
  }

  static async updateEntreprise(
    entreprise: GatewayEntreprise.UpdateEntrepriseAControllerRequestType
  ): Promise<GatewayEntreprise.EntrepriseAControllerResponseType> {
    const res =
      await userSession.axiosInstance.patch<GatewayEntreprise.EntrepriseAControllerResponseType>(
        GatewayEntreprise.entrepriseControllerUrl,
        entreprise
      );
    return res.data;
  }

  static async setHasReachedLimit(
    hasReachedLimit: GatewayEntreprise.AAtteintLeTarif
  ) {
    return userSession.axiosInstance.patch(
      `${GatewayEntreprise.entrepriseControllerUrl}/has-reached-limit`,
      { hasReachedLimit }
    );
  }

  static async updateEntreprisePrincipale(
    entreprise: GatewayEntreprise.UpdateEntreprisePrincipaleRequestType
  ): Promise<GatewayEntreprise.EntreprisePrincipaleResponseType> {
    const { data } =
      await userSession.axiosInstance.patch<GatewayEntreprise.EntreprisePrincipaleResponseType>(
        `${GatewayEntreprise.entrepriseControllerUrl}/principale`,
        entreprise
      );

    return data;
  }

  static async deleteEntreprise(
    deleteEntrepriseRequest: GatewayEntreprise.DeleteEntrepriseRequestType
  ): Promise<void> {
    return userSession.axiosInstance.delete(
      GatewayEntreprise.entrepriseControllerUrl,
      {
        data: deleteEntrepriseRequest,
      }
    );
  }

  static async getByIdForAdmin(idEntreprise: string) {
    const { data } =
      await userSession.axiosInstance.get<GatewayEntreprise.EntreprisePrincipaleResponseType>(
        `${GatewayEntreprise.entrepriseControllerUrl}/${idEntreprise}`
      );
    return data;
  }

  static async setIsDouleAuthEnabled(
    payload: GatewayEntreprise.SetIsDoubleAuthEnabledType
  ): Promise<void> {
    return userSession.axiosInstance.post(
      GatewayEntreprise.setIsDoubleAuthEnabledUrl,
      payload
    );
  }
}
