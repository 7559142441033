export const OrganisationType = {
  VENTE_A_DISTANCE: 'VENTE_A_DISTANCE',
  DEMARCHAGE_TELEPHONIQUE: 'DEMARCHAGE_TELEPHONIQUE',
  RECOURS_MANDATAIRES_OU_CONVENTIONS: 'RECOURS_MANDATAIRES_OU_CONVENTIONS',
  VENTE_DIRECTE: 'VENTE_DIRECTE',
  VENTES_B2B2C: 'VENTES_B2B2C',
  VENTE_EN_LIGNE: 'VENTE_EN_LIGNE',
  FACE_A_FACE: 'FACE_A_FACE',
} as const;
export type OrganisationType =
  (typeof OrganisationType)[keyof typeof OrganisationType];
export const ActiviteIas = {
  ASSURANCE_VIE_DONT_CONTRAT_DE_CAPITALISATION:
    'ASSURANCE_VIE_DONT_CONTRAT_DE_CAPITALISATION',
  SANTE_PREVOYANCE_DEPENDANCE: 'SANTE_PREVOYANCE_DEPENDANCE',
  OBSEQUES: 'OBSEQUES',
  EMPRUNTEUR: 'EMPRUNTEUR',
  AUTOMOBILE: 'AUTOMOBILE',
  MULTIRISQUES_HABITATION: 'MULTIRISQUES_HABITATION',
  CONSTRUCTION: 'CONSTRUCTION',
  AFFINITAIRES: 'AFFINITAIRES',
  PROTECTION_JURIDIQUE: 'PROTECTION_JURIDIQUE',
  ASSISTANCE: 'ASSISTANCE',
  GRANDS_RISQUES: 'GRANDS_RISQUES',
  REASSURANCE: 'REASSURANCE',
  AUTRES: 'AUTRES',
  AUCUNE_AUTRE_ACTIVITE: 'AUCUNE_AUTRE_ACTIVITE',
} as const;
export type ActiviteIas = (typeof ActiviteIas)[keyof typeof ActiviteIas];

export const ActiviteIobsp = {
  CREDIT_IMMOBILIER: 'CREDIT_IMMOBILIER',
  CREDIT_A_LA_CONSOMMATION: 'CREDIT_A_LA_CONSOMMATION',
  REGROUPEMENTS_DE_CREDIT: 'REGROUPEMENTS_DE_CREDIT',
  PRET_VIAGER_HYPOTHECAIRE: 'PRET_VIAGER_HYPOTHECAIRE',
  SERVICES_DE_PAIEMENT: 'SERVICES_DE_PAIEMENT',
  CREDIT_PROFESSIONNEL: 'CREDIT_PROFESSIONNEL',
  REASSURANCE: 'REASSURANCE',
  AUTRES: 'AUTRES',
  AUCUNE_AUTRE_ACTIVITE: 'AUCUNE_AUTRE_ACTIVITE',
} as const;
export type ActiviteIobsp = (typeof ActiviteIobsp)[keyof typeof ActiviteIobsp];
export const declarationActiviteUrl = (
  siren = ':siren',
  millesime: number | string = ':millesime',
) => `declaration-activite/${siren}/${millesime}`;

export type DeclarationActiviteOrganisation = {
  type: OrganisationType;
  repartitionClientele: number;
};
export type DeclarationActiviteIas = {
  siren: string;
  millesime: number;
  organisations: DeclarationActiviteOrganisation[];
  pourcentageCaClientsParticuliers: number | null;
  pourcentageCaClientsProfessionnels: number | null;
  pourcentageTnsTpe: number | null;
  effectifGlobalEntreprise: number | null;
  activitePrincipale: ActiviteIas | null;
  activites10Pourcent: ActiviteIas[];
  fournisseurs: string[];
};
export type DeclarationActiviteIobsp = {
  siren: string;
  millesime: number;
  organisations: DeclarationActiviteOrganisation[];
  pourcentageCaClientsParticuliers: number | null;
  pourcentageCaClientsProfessionnels: number | null;
  pourcentageTnsTpe: number | null;
  effectifGlobalEntreprise: number | null;
  activitePrincipale: ActiviteIobsp | null;
  activites10Pourcent: ActiviteIobsp[];
  fournisseurs: string[];
};

export type CopyDeclarationActiviteIobsp = DeclarationActiviteIobsp & {
  sirens: string[];
};

export type CopyDeclarationActiviteIas = DeclarationActiviteIas & {
  sirens: string[];
};
