import {
  GatewayAssujetti,
  GatewayFormationContinue,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { FieldSet } from "@src/components/FieldSet/FieldSet";
import { useCallback } from "react";
import {
  ConformiteFormFormationIasContent,
  useFormationContinueIasForm,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsFormation/ConformiteFormFormationIAS/ConformiteFormFormationIasContent";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { isMulti } from "@src/assujettis/useAssujettiAvancement";
import { useControleQuinquennalForm } from "./useControleQuinquennalForm";
import { ControleQuinquennalFormTitle } from "./ControleQuinquennalFormTitle";

export const useFormationContinueIas = (
  assujetti: GatewayAssujetti.AssujettiWithAvancement | undefined,
  needed: boolean
) => {
  const call = useCallback(() => {
    if (!assujetti) return Promise.resolve(null);
    return GatewayFormationContinueApi.getIasAdmin(
      assujetti.id,
      assujetti.millesime
    );
  }, [assujetti]);
  const { content, hasLoaded } = useControleQuinquennalForm(
    assujetti,
    needed,
    call
  );
  return {
    formationContinueIas: content,
    hasLoadedFormationContinueIas: hasLoaded,
  };
};
type ControleQuinquennalAssujettiSidePaneContentFormationContinueIasProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  formationContinue: GatewayFormationContinue.FormationContinueIas | null;
  categories: GatewayInfoSocieteOrias.Categorie[];
};

export function ControleQuinquennalAssujettiSidePaneContentFormationContinueIas({
  assujetti,
  formationContinue,
  categories,
}: ControleQuinquennalAssujettiSidePaneContentFormationContinueIasProps) {
  const props = useFormationContinueIasForm(formationContinue, categories, []);
  const isMultiActivite = isMulti(categories);
  return (
    <>
      <ControleQuinquennalFormTitle
        assujetti={assujetti}
        title="Formation Continue IAS"
        avancement="formationContinueIas"
      />
      <Form formConfig={props.formConfig} onSubmit={() => null}>
        <FieldSet disabled>
          <ConformiteFormFormationIasContent
            {...props}
            data={{ assujetti }}
            hideTitle
            isMultiActivite={isMultiActivite}
          />
        </FieldSet>
      </Form>
    </>
  );
}
