import { Assujetti } from "@src/store/store.definition";
import { AssujettiFilterHookReturn } from "@src/assujettis/AssujettiMainList/useAssujettiFilter";
import {
  Button,
  displayErrorToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { useEntreprises } from "@src/store/store.entreprise";
import { ReactComponent as TrashIcon } from "@src/assets/icons/trash.svg";
import { GatewayAssujettiApi } from "@src/api/assujetti.api";
import { useMillesime } from "@src/store/store.millesime";
import { useAssujetisAvancement } from "@src/assujettis/useAssujettiAvancement";
import { RoleAssujettiLabels } from "@src/assujettis/AssujettiConsts";
import { GatewayContact } from "@conformite/gateway";
import { useIsAllowed } from "@src/session/useIsAllowed";
import { recordToSelectOptions } from "@src/adhesion/AdhesionData/adhesionData";
import { ListTemplate } from "../ListTemplate/ListTemplate";
import { FilterSearch } from "../FilterSearch/FilterSearch";
import { InputSearch } from "../InputSearch/InputSearch";
import { Table } from "../Table/Table";
import { IconStatus } from "../IconStatus/IconStatus";
import { useDialog } from "../Dialog/useDialog";
import { Pagination } from "../Pagination/Pagination";
import styles from "./AssujettiList.module.scss";
import { ColumnDefinition } from "../Table/Table.definition";
import { FilterDropdown } from "../Filter/FilterDropdown";
import { FilterMultiSelect } from "../Filter/FilterMultiSelect";
import { SortSelect } from "../Filter/SortSelect";
import { SortDropdown } from "../Filter/SortDropdown";
import { Link } from "../Link/Link";

const insertIf = (condition: boolean, value: ColumnDefinition<Assujetti>) =>
  condition ? [value] : [];

export type AssujettiComponent = (props: {
  assujetti: Assujetti;
}) => JSX.Element;

type AssujettiListProps = {
  onRowClick?: (row: Assujetti, index: number) => void;
  HeaderActions?: React.ReactNode;
  removeEntries: (ids: string[], millesime: number) => void;
  AssujetiConformite: AssujettiComponent;
} & AssujettiFilterHookReturn;

export function AssujettiList({
  onRowClick,
  content: assujettis,
  numberOfContentUnpaginated,
  content,
  page,
  setPage,
  pageSize,
  filtersSelected,
  setFilterSelected,
  sortSelected,
  setSortSelected,
  HeaderActions,
  removeEntries,
  AssujetiConformite,
}: AssujettiListProps) {
  const { hasAllowedRole } = useIsAllowed();
  const { entreprisesBySiren } = useEntreprises();
  const { selected: millesime } = useMillesime();
  const { openConfirmDialog } = useDialog();
  const { getAssujetisAvancement } = useAssujetisAvancement();
  function isConform(assujetti: Assujetti) {
    return getAssujetisAvancement(assujetti) === 100;
  }
  async function deleteMultipleAssujetis(selectedRows: Assujetti[]) {
    if (!millesime) return;
    const shouldContinue = await openConfirmDialog({
      title:
        "Êtes-vous sur(e) de vouloir supprimer ces personnes assujetties ?",
      message: "Cette action est irréversible.",
      confirmText: "Confirmer",
      cancelText: "Annuler",
    });

    if (!shouldContinue) return;

    await GatewayAssujettiApi.deleteMultiple(
      selectedRows.map((r) => r.id),
      millesime
    );
    removeEntries(
      selectedRows.map((r) => r.id),
      millesime
    );
  }
  async function deleteOneAssujeti(assujetti: Assujetti) {
    if (!millesime) return;
    const shouldContinue = await openConfirmDialog({
      title:
        "Êtes-vous sur(e) de vouloir supprimer cette personne assujettie ?",
      message: "Cette action est irréversible.",
      confirmText: "Confirmer",
      cancelText: "Annuler",
    });

    if (!shouldContinue) return;

    await GatewayAssujettiApi.deleteMultiple([assujetti.id], millesime);
    removeEntries([assujetti.id], millesime);
  }

  const ctaNoData = !content.length ? (
    <Link to="/personnes-assujetties">
      <Button variant="outline">Ajouter vos personnes assujetties</Button>
    </Link>
  ) : null;

  return (
    <ListTemplate
      isLoading={!assujettis || !entreprisesBySiren}
      header={
        <FilterSearch
          search={
            <InputSearch
              placeholder="Prénom, Nom, Raison sociale"
              name="search"
              value={filtersSelected?.search[0]}
              onChange={(e) => {
                setFilterSelected("search", [e.target.value]);
              }}
            />
          }
          filter={
            <>
              <FilterDropdown
                filtersSelected={filtersSelected}
                blacklist={["search"]}
              >
                <FilterMultiSelect
                  name="filterConformite"
                  label="Conformité"
                  filtersSelected={filtersSelected}
                  setFilterSelected={setFilterSelected}
                  keyFitler="conformite"
                  options={[
                    {
                      value: "true",
                      label: "Conforme",
                    },
                    {
                      value: "false",
                      label: "Non conforme",
                    },
                  ]}
                />
                <FilterMultiSelect
                  name="filterFonction"
                  label="Fonction"
                  filtersSelected={filtersSelected}
                  setFilterSelected={setFilterSelected}
                  keyFitler="fonction"
                  options={recordToSelectOptions(RoleAssujettiLabels)}
                />
              </FilterDropdown>
              <SortDropdown>
                <SortSelect
                  name="sort"
                  sortSelected={sortSelected}
                  setSortSelected={setSortSelected}
                  options={[
                    {
                      label: "Prénom Nom",
                      value: "name",
                    },
                    {
                      label: "Conformite",
                      value: "conformite",
                    },
                  ]}
                />
              </SortDropdown>
            </>
          }
          action={HeaderActions}
        />
      }
      table={
        !!assujettis &&
        !!entreprisesBySiren && (
          <Table<Assujetti>
            selectionOptions={
              hasAllowedRole(
                GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
                GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
              )
                ? {
                    renderActions: (selectedRows) => (
                      <Button
                        iconVariant="left"
                        className={styles.deleteMultipleButton}
                        icon={<TrashIcon />}
                        onClick={() => {
                          deleteMultipleAssujetis(selectedRows).catch(() => {
                            displayErrorToast(
                              "une erreur est survenue lors de la suppression"
                            );
                          });
                        }}
                        variant="text"
                      >
                        Supprimer les personnes
                      </Button>
                    ),
                  }
                : undefined
            }
            data={content}
            onRowClick={onRowClick}
            columns={[
              {
                key: "validSociete",
                size: "auto",
                render: (item) => <IconStatus isValid={isConform(item)} />,
              },
              {
                key: "name",
                title: "Prénom Nom",
                render: (assujetti) => (
                  <p>{`${assujetti.surname} ${assujetti.name}`}</p>
                ),
                size: 1.5,
              },
              {
                key: "company",
                title: "Société",
                size: 1,
                render: (assujetti) => (
                  <p>
                    {entreprisesBySiren[assujetti.sirenCompany]?.raisonSociale}
                  </p>
                ),
              },
              {
                key: "role",
                title: "Fonction",
                size: 1,
                render: (assujetti) => (
                  <p>{RoleAssujettiLabels[assujetti.role]}</p>
                ),
              },
              {
                key: "compliance",
                title: "Conformité",
                size: "auto",
                render: (item) => (
                  <AssujetiConformite assujetti={item} />
                  // <ComplianceProgressBar
                  //   progress={getAssujetisAvancement(item)}
                  // />
                ),
              },
              ...insertIf(
                hasAllowedRole(
                  GatewayContact.ContactRoleUtilisateur.ADMINISTRATEUR,
                  GatewayContact.ContactRoleUtilisateur.RESPONSABLE_CONFORMITE
                ),
                {
                  key: "actions",
                  size: "auto",
                  render: (assujetti) => (
                    <div className={styles.actions}>
                      <Button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteOneAssujeti(assujetti).catch(() => {
                            displayErrorToast(
                              "une erreur est survenue lors de la suppression"
                            );
                          });
                        }}
                        className={styles.trashButton}
                        variant="text"
                        iconVariant="left"
                        icon={<TrashIcon />}
                      />
                    </div>
                  ),
                }
              ),
            ]}
            textNoData="Aucune personne assujettie renseignée"
            ctaNoData={ctaNoData}
          />
        )
      }
      footer={
        <Pagination
          page={page}
          pageSize={pageSize}
          total={numberOfContentUnpaginated}
          onChange={setPage}
        />
      }
    />
  );
}
