import {
  Button,
  Card,
  FormTitle,
  displaySucessToast,
} from "@lya-protect/lya-protect-form-library/dist/UI";
import { ReactComponent as ChevronLeft } from "@src/assets/icons/chevron-left.svg";
import { Link } from "@src/components/Link/Link";
import { MainContentPageTemplate } from "@src/components/MainPageTemplate/MainContentPageTemplate";
import { useAssujetti } from "@src/store/store.assujetti";
import { useEntreprises } from "@src/store/store.entreprise";
import { useParams, useSearchParams } from "react-router-dom";
import { IconStatus } from "@src/components/IconStatus/IconStatus";
import { useEffect, useState } from "react";
import {
  GatewayAssujetti,
  GatewayEntreprise,
  GatewayFormationContinue,
} from "@conformite/gateway";
import { Form } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import {
  ConformiteFormFormationIasContent,
  useFormationContinueIas,
} from "@src/assujettis/AssujettiPaneContent/pages/AssujettiParcoursConformite/Steps/FormsFormation/ConformiteFormFormationIAS/ConformiteFormFormationIasContent";
import { GatewayFormationContinueApi } from "@src/api/conformite/formationContinue.api";
import { useOnce } from "@src/hooks/useOnce";
import { isMulti } from "@src/assujettis/useAssujettiAvancement";
import styles from "../assujettis/AssujettiForm.module.scss";

type ConformiteFormationContinueContentProps = {
  assujetti: GatewayAssujetti.AssujettiWithAvancement;
  entreprise: GatewayEntreprise.EntrepriseAControllerResponseType;
  fromAxe: string | null;
  formationContinue: GatewayFormationContinue.FormationContinueIas | null;
};
function ConformiteFormationContinueContent({
  assujetti,
  fromAxe,
  entreprise,
  formationContinue,
}: ConformiteFormationContinueContentProps) {
  const isMultiActivite = isMulti(entreprise.categoriesActivites);

  const {
    lastVerifiedAt,
    onSubmit,
    formConfig,
    totalHours,
    fields,
    remove,
    append,
    soumisFormationContinue,
    hasOnlyActiviteIasSecondaire,
  } = useFormationContinueIas(
    formationContinue,
    entreprise,
    { assujetti },
    () => {
      displaySucessToast("La conformité a bien été mise à jour");
    }
  );
  useOnce(() => {
    if (formationContinue !== null) formConfig.trigger().catch(console.error);
  });
  if (!entreprise || !assujetti) return null;

  return (
    <MainContentPageTemplate
      title={
        <FormTitle className={styles.header}>
          <h1 className={styles.title}>
            <b>Formation Continue IAS</b> - {assujetti.surname} {assujetti.name}
          </h1>
          <IconStatus
            isValid={assujetti.avancement.formationContinueIas === 100}
          />
        </FormTitle>
      }
      actionNavigation={
        <Link
          to={`/conformite/formation-continue/ias/${entreprise.siren}${
            fromAxe === null ? "" : "?fromAxe"
          }`}
        >
          <Button variant="text" icon={<ChevronLeft />}>
            Retour
          </Button>
        </Link>
      }
    >
      <Card>
        <Form formConfig={formConfig} onSubmit={onSubmit}>
          <ConformiteFormFormationIasContent
            hideTitle
            data={{ assujetti }}
            lastVerifiedAt={lastVerifiedAt}
            totalHours={totalHours}
            fields={fields}
            remove={remove}
            append={append}
            soumisFormationContinue={soumisFormationContinue}
            hasOnlyActiviteIasSecondaire={hasOnlyActiviteIasSecondaire}
            isMultiActivite={isMultiActivite}
          />
        </Form>
        <div className={styles.action}>
          <Button
            onClick={() => {
              onSubmit(formConfig.getValues()).catch((e) => {
                console.error(e);
              });
            }}
          >
            Sauvegarder
          </Button>
        </div>
      </Card>
    </MainContentPageTemplate>
  );
}

export function ConformiteFormationContinueIas() {
  const { entreprisesBySiren } = useEntreprises();
  const { assujettis } = useAssujetti();
  const { siren, id } = useParams();
  const entreprise = entreprisesBySiren?.[siren || ""];
  const assujetti = assujettis?.[id || ""];
  const [query] = useSearchParams();
  const fromAxe = query.get("fromAxe");
  const [formationContinue, setFormationContinue] =
    useState<GatewayFormationContinue.FormationContinueIas | null>();
  useEffect(() => {
    if (!assujetti) return;
    GatewayFormationContinueApi.getIAS(assujetti.id, assujetti.millesime)
      .then((data) => {
        setFormationContinue(data);
      })
      .catch((e) => {
        console.error(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assujetti]);
  if (!entreprise || !assujetti || !siren || formationContinue === undefined)
    return null;
  return (
    <ConformiteFormationContinueContent
      entreprise={entreprise}
      assujetti={assujetti}
      fromAxe={fromAxe}
      formationContinue={formationContinue}
    />
  );
}
