import {
  GatewayAdresse,
  GatewayEntreprise,
  GatewayInfoSocieteOrias,
} from "@conformite/gateway";
import { zod } from "@lya-protect/lya-protect-form-library/dist/Exports";
import { FieldErrors } from "@lya-protect/lya-protect-form-library/dist/Exports/reactHookForm.exports";
import { useFormConfig } from "@lya-protect/lya-protect-form-library/dist/FormAPI";
import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { useEffect, useState } from "react";

interface UseEditEntrepriseProps {
  entreprise: AdhesionDataEntreprisesItem | undefined;
  onSubmit: (data: AdhesionDataEntreprisesItem) => void;
}

const schema = zod.object({
  siren: zod
    .string({ required_error: `SIREN non renseigné` })
    .refine((v) => /^\d{9}$/.test(v), "SIREN invalide"),
  raisonSociale: zod.string(),
  trancheEffectif: zod.nativeEnum(GatewayEntreprise.TrancheEffectif, {
    required_error: "Tranche d'effectif invalide",
  }),
  typeVoie: zod.nativeEnum(GatewayAdresse.TypeVoie, {
    required_error: "Type de voie invalide",
  }),
  libelleVoie: zod.string(),
  codePostal: zod
    .string({ required_error: "Code postal non renseigné" })
    .refine((v) => /^\d{5}$/.test(v), "Code postal invalide"),
  ville: zod.string(),
  categories: zod
    .array(
      zod.nativeEnum(GatewayInfoSocieteOrias.Categorie, {
        required_error: "Catégorie ORIAS invalide",
      })
    )
    .min(1, "1 Catégorie ORIAS au minimum"),
  organisationsProfessionnelles: zod
    .array(
      zod.nativeEnum(GatewayInfoSocieteOrias.OrganisationProfessionnelle, {
        required_error: "Organisation professionnelle invalide",
      })
    )
    .min(1, "1 organisation professionnelle minimum"),
  chiffreAffaire: zod.number({
    required_error: "Chiffre d'affaire invalide",
  }),
  categorieRepresentation: zod.nativeEnum(
    GatewayInfoSocieteOrias.CategorieRepresentation,
    { required_error: "Catégorie de représentation invalide" }
  ),
  pays: zod.nativeEnum(GatewayAdresse.Pays, {
    required_error: "Pays invalide",
  }),
  formeJuridique: zod.nativeEnum(GatewayEntreprise.FormeJuridique, {
    required_error: "Forme juridique invalide",
  }),
});

export type EditEntrepriseSchema = zod.infer<typeof schema>;

export const useEntrepriseImportEdit = ({
  entreprise,
  onSubmit,
}: UseEditEntrepriseProps) => {
  const [initialErrors, setInitialErrors] =
    useState<FieldErrors<EditEntrepriseSchema> | null>(null);

  const formConfig = useFormConfig<typeof schema, EditEntrepriseSchema>({
    schema,
    defaultValues: {
      ...entreprise,
      categories: entreprise?.categoriesOrias,
      organisationsProfessionnelles: entreprise?.organisationPro,
    },
  });

  const initializeForm = async () => {
    await formConfig.trigger();
  };

  useEffect(() => {
    initializeForm().catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (formConfig?.formState?.errors && initialErrors === null) {
      setInitialErrors({ ...formConfig.formState.errors });
    }
  }, [formConfig?.formState?.errors, initialErrors]);

  const handleSubmit = (data: EditEntrepriseSchema) => {
    const formatedData: AdhesionDataEntreprisesItem = {
      ...data,
      categoriesOrias: data.categories,
      organisationPro: data.organisationsProfessionnelles,
    };
    onSubmit(formatedData);
  };

  return {
    formConfig,
    handleSubmit,
    initialErrors,
  };
};
