function LinkDocumentationHere() {
  return <a href="https://aide.endya.fr/faq/outils-conformite">ici</a>;
}

export function ControleQuinquennalExplainAxeGarantieFinanciereIas() {
  return (
    <div>
      <p>
        Si vous percevez, même à titre occasionnel, des fonds pour le compte de
        tiers (entreprise d&apos;assurance/assuré) dans le cadre de
        l&apos;activité d&apos;IAS, les documents sollicités ci-dessous
        permettront de vérifier la cohérence de la garantie financière souscrite
        avec les fonds réellement encaissés, déduction faite des mandats
        d&apos;encaissement.
      </p>
      <br />
      <p>
        Pièces à fournir, que vous perceviez ou non des fonds pour le compte ou
        à destination de tiers :
      </p>
      <ul>
        <li>
          Le(s) éventuel(s) mandat(s) exprès d&apos;encaissement régularisés
          avec une entreprise d&apos;assurance
        </li>
        <li>
          Attestation de votre expert-comptable ou commissaire aux comptes
          précisant le détail des fonds encaissés au sens des articles L.512-7
          et A. 512-5 du code des assurances. (cf. modèle accessible dans la
          documentation Endya disponible <LinkDocumentationHere />)
        </li>
        <p>
          <b>OU</b>, à défaut, si vous n&apos;avez pas d&apos;expert-comptable
          ou commissaire aux comptes ou si ce dernier ne souhaite/peut pas
          compléter l&apos;attestation :
        </p>
        <li>
          Les comptes classes 4 et 5 de votre grand livre et votre bilan N-1.
        </li>
      </ul>
    </div>
  );
}
