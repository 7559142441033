import { AdhesionDataEntreprisesItem } from "@src/adhesion/AdhesionData/adhesionDataEntreprises";
import { ImportSocietesError } from "@src/adhesion/Steps/AdhesionModeImport/useImportSocietes";
import { entrepriseExcelTemplate } from "@src/societes/export/EntrepriseExcelTemplate";
import axios from "axios";
import { Row, Workbook, Worksheet } from "exceljs";
import { get } from "lodash";
import {
  ExcelPropertyType,
  ExcelTemplate,
  ExportCellTemplate,
} from "./excel.definition";

const NUMBER_OF_LINE_TO_VALIDATE = 2000;

export async function downloadSheetFromWorkbook(
  fileName: string,
  workbook: Workbook
) {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

export async function importWorkBookFromFile(
  filePath: string
): Promise<Workbook> {
  const workbook = new Workbook();
  const response = await axios.get(`/${filePath}`, {
    responseType: "arraybuffer",
  });
  return workbook.xlsx.load(response.data as ArrayBuffer);
}

export function insertDataInSheet<TTemplate extends ExcelTemplate>(
  rows: unknown[],
  sheet: Worksheet,
  template: TTemplate,
  exportTemplate: ExportCellTemplate[]
) {
  sheet.duplicateRow(template.firstRowIndex + 1, rows.length, true);
  rows.forEach((row, index) => {
    sheet.getRow(index + template.firstRowIndex + 1).values =
      exportTemplate.map((cellTemplate) => {
        if (!cellTemplate) return null;
        const rawValue = get(row, cellTemplate.path) as
          | string
          | boolean
          | number
          | Date
          | null
          | undefined;
        if (rawValue === null || rawValue === undefined) return null;
        switch (cellTemplate.type) {
          case ExcelPropertyType.boolean:
            return rawValue ? "OUI" : "NON";
          case ExcelPropertyType.date:
            return rawValue;
          case ExcelPropertyType.number:
            return rawValue;
          case ExcelPropertyType.string:
          default:
            return rawValue;
        }
      });
  });
}
const formulaeOuiNon = "=Données!$C$2:$C$200";
const formulaeSocieteByColumn: Record<string, string> = {
  C: "=Données!$A$2:$A$200",
  E: "=Données!$O$2:$O$200",
  K: "=Données!$M$2:$M$200",
  L: "=Données!$E$2:$E$200",
  N: formulaeOuiNon,
  O: formulaeOuiNon,
  P: formulaeOuiNon,
  Q: formulaeOuiNon,
  R: formulaeOuiNon,
  S: formulaeOuiNon,
  T: formulaeOuiNon,
  U: formulaeOuiNon,
  V: "=Données!$G$2:$G$200",
  W: "=Données!$G$2:$G$200",
  X: "=Données!$G$2:$G$200",
  Y: "=Données!$G$2:$G$200",
  Z: "=Données!$G$2:$G$200",
  AA: "=Données!$G$2:$G$200",
  AB: "=Données!$G$2:$G$200",
  AC: "=Données!$G$2:$G$200",
  AD: "=Données!$G$2:$G$200",
  AE: "=Données!$G$2:$G$200",
  AF: "=Données!$G$2:$G$200",
  AG: "=Données!$G$2:$G$200",
  AH: "=Données!$I$2:$I$200",
  AI: "=Données!$I$2:$I$200",
  AJ: "=Données!$I$2:$I$200",
  AK: "=Données!$K$2:$K$200",
};
const formulaeAssujettiByColumn: Record<string, string> = {
  D: "=Données!$A$2:$A$200",
  G: formulaeOuiNon,
  N: formulaeOuiNon,
  O: "=Données!$E$2:$E$200",
  P: "=Données!$I$2:$I$200",
  Q: "=Données!$K$2:$K$200",
  S: "=Données!$O$2:$O$200",
  T: "=Données!$S$2:$S$200",
  AF: formulaeOuiNon,
  AG: "=Données!$G$2:$G$200",
  AH: "=Données!$I$2:$I$200",
  AI: "=Données!$M$2:$M$200",
  AK: "=Données!$Q$2:$Q$200",
  AL: "=Données!$U$2:$U$200",
};
function setDataValidationList(row: Row, column: string, formulae: string) {
  row.getCell(column).dataValidation = {
    type: "list",
    allowBlank: true,
    formulae: [formulae],
  };
}

export const TEMPLATE_SOCIETES_FIRST_ROW = 3;
export function addSocieteDataValidationToSheet(
  worksheet: Worksheet,
  numberOfEditedLines: number
) {
  worksheet.insertRows(
    numberOfEditedLines + TEMPLATE_SOCIETES_FIRST_ROW,
    new Array(NUMBER_OF_LINE_TO_VALIDATE + numberOfEditedLines)
      .fill(null)
      .map(() => [undefined, undefined, undefined, ""])
  );
  worksheet.eachRow((row, index) => {
    if (index < TEMPLATE_SOCIETES_FIRST_ROW) return;
    Object.entries(formulaeSocieteByColumn).forEach(([column, formulae]) => {
      setDataValidationList(row, column, formulae);
    });
  });
}
export const TEMPLATE_ASSUJETTIS_FIRST_ROW = 4;
export function addAssujettiDataValidationToSheet(
  worksheet: Worksheet,
  numberOfEditedLines: number
) {
  worksheet.insertRows(
    numberOfEditedLines + TEMPLATE_ASSUJETTIS_FIRST_ROW,
    new Array(NUMBER_OF_LINE_TO_VALIDATE + numberOfEditedLines)
      .fill(null)
      .map(() => [undefined, undefined, undefined, ""])
  );
  worksheet.eachRow((row, index) => {
    if (index < TEMPLATE_ASSUJETTIS_FIRST_ROW) return;
    Object.entries(formulaeAssujettiByColumn).forEach(([column, formulae]) => {
      setDataValidationList(row, column, formulae);
    });
  });
}

function excelColToIndex(col: string): number {
  const upperCol = col.toUpperCase();
  let colNumber = 0;
  const len = col.length;
  for (let i = 0; i < len; i += 1) {
    colNumber += 26 ** (len - i - 1) * (upperCol.charCodeAt(i) - 64);
  }
  return colNumber - 1;
}

export function getExportTemplateFromTemplate(
  template: ExcelTemplate
): ExportCellTemplate[] {
  let maxColIndex = 0;

  const recordOfExportCellTemplate = Object.entries(template.categories).reduce<
    Record<number, ExportCellTemplate>
  >((acc, [categoryName, category]) => {
    const categoryColumns = Object.entries(category.columns);
    categoryColumns.forEach(([columnName, column]) => {
      const colIndex = excelColToIndex(column.col);
      maxColIndex = Math.max(maxColIndex, colIndex);
      acc[colIndex] = {
        path: `${categoryName}.${columnName}`,
        type: column.type,
      };
    });
    return acc;
  }, {});
  return Array.from({ length: maxColIndex + 1 }).map(
    (_, index) => recordOfExportCellTemplate[index] || null
  );
}

export function colorErrorsInSheet(
  sheet: Worksheet,
  entreprises: ImportSocietesError[]
) {
  sheet.eachRow((row, rowIndex) => {
    if (rowIndex < TEMPLATE_SOCIETES_FIRST_ROW) return;

    const entrepriseIndex = rowIndex - TEMPLATE_SOCIETES_FIRST_ROW;
    const entreprise = entreprises[entrepriseIndex];
    if (!entreprise.errors || entreprise.errors.length === 0) return;
    const rowErrorsKeys = entreprise.errors.map(({ key }) => key);
    const columns = rowErrorsKeys.flatMap((e) => {
      const endWithNumber = [
        "organisationPro",
        "sousCategorieCobsp",
        "sousCategorieMiobsp",
      ];
      const columnKey = endWithNumber.includes(e) ? `${e}1` : e;
      const categoriesOriasKey: keyof AdhesionDataEntreprisesItem =
        "categoriesOrias";
      const categoriesOriasColumnsKeys = [
        "categorieOriasCOA",
        "categorieOriasMIA",
        "categorieOriasCOBSP",
        "categorieOriasMIOBSP",
      ];
      if (e === categoriesOriasKey)
        return categoriesOriasColumnsKeys.map(
          (k) => entrepriseExcelTemplate.categories.main.columns[k]?.col
        );
      return entrepriseExcelTemplate.categories.main.columns[columnKey]?.col;
    });

    if (!columns || columns.length === 0) return;
    columns.forEach((col) => {
      row.getCell(col).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" },
      };
    });
  });
}
